/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'toggle2-off': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 00-1-3h4a3 3 0 110 6z"/><path pid="1" d="M5 12a4 4 0 110-8 4 4 0 010 8m0 1A5 5 0 105 3a5 5 0 000 10"/>',
    },
});
